import { setExperiencePlace } from 'actions/ExperienceActions'
import Flag from 'components/Luxkit/Flag'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextButton from 'components/Luxkit/Button/TextButton'
import LineGlobeIcon from 'components/Luxkit/Icons/line/LineGlobeIcon'
import LineLocationArrowIcon from 'components/Luxkit/Icons/line/LineLocationArrowIcon'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import SolidSearchIcon from 'components/Luxkit/Icons/solid/SolidSearchIcon'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaQueryUp } from 'components/utils/breakpoint'
import config from 'constants/config'
import Places, { getPlaceByRegionCode } from 'constants/places'
import GeoContext from 'contexts/geoContext'
import switchFunc from 'lib/function/switchFunc'
import { rem } from 'polished'
import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import { useAppDispatch } from 'hooks/reduxHooks'
import styled from 'styled-components'
import ExperiencesPageLocationModalItem from './ExperiencesPageLocationModalItem'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import useTrackExperienceOfferEvent from 'contexts/support/useTrackExperienceOfferEvent'

const DestinationGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${mediaQueryUp.tablet} {
    grid-template-columns: 1fr 1fr;
    column-gap: ${rem(20)};
    row-gap: ${rem(8)};
  }
`

const SearchSection = styled(VerticalSpacer)`
  border: 1px solid ${props => props.theme.palette.neutral.default.five};
  border-radius: ${props => props.theme.borderRadius.M};
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(16)};

  ${mediaQueryUp.tablet} {
    display: flex;
    gap: ${rem(24)};
  }
`

interface PlaceItem {
  place: App.Place;
  nameOverride?: string;
  icon?: React.ReactNode;
}

const getAustralianCities = switchFunc<Array<PlaceItem>>({
  luxuryescapes: [
    { place: Places.Australia, nameOverride: 'All Australia', icon: <Flag countryCode="au" /> },
    { place: Places.Sydney, icon: <LineMapMarkerIcon /> },
    { place: Places.Brisbane, icon: <LineMapMarkerIcon /> },
    { place: Places.Melbourne, icon: <LineMapMarkerIcon /> },
    { place: Places.Perth, icon: <LineMapMarkerIcon /> },
    { place: Places.Adelaide, icon: <LineMapMarkerIcon /> },
  ],
}, [
  { place: Places.Australia, nameOverride: 'All Australia', icon: <Flag countryCode="au" /> },
  { place: Places.Sydney, icon: <LineMapMarkerIcon /> },
  { place: Places.Melbourne, icon: <LineMapMarkerIcon /> },
  { place: Places.GoldCoast, icon: <LineMapMarkerIcon /> },
  { place: Places.Perth, icon: <LineMapMarkerIcon /> },
  { place: Places.Brisbane, icon: <LineMapMarkerIcon /> },
  { place: Places.Hobart, icon: <LineMapMarkerIcon /> },
])

const NewZealandCities: Array<PlaceItem> = [
  { place: Places.NewZealand, nameOverride: 'All New Zealand', icon: <Flag countryCode="nz" /> },
  { place: Places.Auckland, icon: <LineMapMarkerIcon /> },
  { place: Places.Wellington, icon: <LineMapMarkerIcon /> },
  { place: Places.Christchurch, icon: <LineMapMarkerIcon /> },
  // { place: Places.Hamilton, icon: <LineMapMarkerIcon /> },
  { place: Places.Queenstown, icon: <LineMapMarkerIcon /> },
]

const regionBlacklist = new Set([
  'hk',
  'cn',
  'de',
  'kr',
  'il',
  'mo',
  'qa',
  'ph',
  'sa',
  'za',
  'tw',
])

export type ExperiencesPageLocationModalResponse = {
  type: App.ExperiencePlaceType,
  place: App.ExperiencePlace
}

interface Props {
  experiencePlace: App.ExperiencePlace;
  onSearchByDestination?: React.MouseEventHandler<HTMLButtonElement>;
}

interface ExperiencesPageLocationModalResult {
  type: App.ExperiencePlaceType
  place: {
      placeId: string | undefined
      userSelected: boolean
  }
}

function ExperiencesPageLocationModal(props: Props) {
  const {
    experiencePlace,
    onSearchByDestination,
  } = props
  const trackEvent = useTrackExperienceOfferEvent()
  const modalElement = useModalElementContext<ExperiencesPageLocationModalResult | undefined>()
  const dispatch = useAppDispatch()
  const geo = useContext(GeoContext)
  const [localPlace, setLocalPlace] = useState<App.ExperiencePlace>(experiencePlace)

  useEffect(() => {
    setLocalPlace(experiencePlace)
  }, [experiencePlace])

  const searchByDestination = useCallback((e) => {
    onSearchByDestination?.(e)
    modalElement?.resolve(undefined)
  }, [modalElement, onSearchByDestination])

  const applySelection = useCallback((type: App.ExperiencePlaceType, placeId, userSelected) => {
    dispatch(setExperiencePlace(type, {
      placeId,
      userSelected,
    }))

    if (type === 'currentLocation') {
      trackEvent('GEOLOCATION_USE_CURRENT_LOCATION_CLICKED')
    } else {
      trackEvent('GEOLOCATION_SELECTED')
    }

    modalElement?.resolve({
      type: localPlace.type,
      place: {
        placeId: localPlace.placeId,
        userSelected: localPlace.userSelected,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps -- trackEvent, dispatch are a stable function
  }, [localPlace, localPlace, localPlace])

  const onSelectRegion = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const type = 'region'
    const placeId = getPlaceByRegionCode(e.currentTarget.dataset.code).id
    const userSelected = true
    setLocalPlace({
      type,
      placeId,
      userSelected,
    })
    applySelection(type, placeId, userSelected)
  }, [applySelection])

  const onSelectPlace = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const type = 'place'
    const placeId = e.currentTarget.dataset.placeid
    const userSelected = true
    setLocalPlace({
      type,
      placeId,
      userSelected,
    })
    applySelection(type, placeId, userSelected)
  }, [applySelection])

  const onCurrentLocationClick = useCallback(() => {
    const type = 'currentLocation'
    const userSelected = true
    setLocalPlace({
      type,
      userSelected,
    })
    applySelection(type, null, userSelected)
  }, [applySelection])

  const localCities = useMemo(() => {
    if (geo.currentRegionCode === 'AU') {
      return getAustralianCities(config.BRAND)
    } else if (geo.currentRegionCode === 'NZ') {
      return NewZealandCities
    }
  }, [geo.currentRegionCode])

  const regionPlaces = useMemo(() => {
    if (config.BRAND === 'luxuryescapes') {
      return config.regions.filter(r => !regionBlacklist.has(r.code.toLowerCase()))
    }
    return []
  }, [])

  return <ModalBase
    size="L"
    onAfterOpen={() => trackEvent('GEOLOCATION_OPENED')}
    onClose={() => trackEvent('GEOLOCATION_CLOSED')}
  >
    <ModalHeader
      title="Explore offers around the world"
      subtitle="We'll show you the best offers around that area"
    />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={20}>
          <DestinationGrid>
            <ExperiencesPageLocationModalItem
              onClick={onCurrentLocationClick}
              icon={<LineLocationArrowIcon />}
              selected={localPlace.type === 'currentLocation' && localPlace.userSelected}
            >
              Use my current location
            </ExperiencesPageLocationModalItem>
          </DestinationGrid>
          {localCities && <VerticalSpacer gap={8}>
            <BodyText variant="medium" weight="bold">{geo.currentRegionName}</BodyText>
            <DestinationGrid>
              {localCities.map(placeItem => <ExperiencesPageLocationModalItem
                key={placeItem.place.id}
                icon={placeItem.icon}
                data-placeid={placeItem.place.id}
                onClick={onSelectPlace}
                selected={
                  localPlace.type === 'place' &&
                  localPlace.placeId === placeItem.place.id
                }
              >
                {placeItem.nameOverride ?? placeItem.place.name}
              </ExperiencesPageLocationModalItem>)}
            </DestinationGrid>
          </VerticalSpacer>}
          {regionPlaces.length > 0 && <VerticalSpacer gap={8}>
            <BodyText variant="medium" weight="bold">Other regions</BodyText>
            <DestinationGrid>
              <ExperiencesPageLocationModalItem
                icon={<LineGlobeIcon />}
                data-placeid={Places.Anywhere.id}
                onClick={onSelectPlace}
                selected={localPlace.placeId === Places.Anywhere.id}
              >
                Worldwide
              </ExperiencesPageLocationModalItem>
              {regionPlaces.map(region => <ExperiencesPageLocationModalItem
                key={region.code}
                icon={<Flag countryCode={region.code} />}
                data-code={region.code}
                onClick={onSelectRegion}
                selected={
                  localPlace.type === 'region' &&
                  getPlaceByRegionCode(region.code).id === localPlace.placeId
                }
              >
                {region.name}
              </ExperiencesPageLocationModalItem>)}
            </DestinationGrid>
          </VerticalSpacer>}
          {onSearchByDestination && (
            <SearchSection gap={16}>
              <div>
                <Heading variant="heading6">Have a place in mind?</Heading>
                <BodyText variant="medium">
                  Search for exclusive offers around the world in any city, country or region.
                </BodyText>
              </div>
              <TextButton
                fit="mobile-full-width"
                onClick={searchByDestination}
                kind="primary"
                startIcon={<SolidSearchIcon />}
              >
                Search by destination
              </TextButton>
            </SearchSection>
          )}
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
  </ModalBase>
}

function mapStateToProps(state: App.State) {
  return {
    experiencePlace: state.experience.experiencePlace,
  }
}

export default connect(mapStateToProps)(ExperiencesPageLocationModal)
